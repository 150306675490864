"use client";

import SignInForm from "./SignInForm";
export default function SignInPage({
  params
}: {
  params: {
    amt: string;
  };
}) {
  return <SignInForm params={params} data-sentry-element="SignInForm" data-sentry-component="SignInPage" data-sentry-source-file="page.tsx" />;
}